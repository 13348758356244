<template>
  <div>
    <div class="fullscreen_background">
      <div class="fullscreen_background__filter"></div>
      <img
        class="fullscreen_background__img"
        :src="require('/src/assets/img/Untitled-2.jpg')"
        alt=""
      />
    </div>

    <b-container>
      <b-row align-h="center" class="fullscreen" align-v="center">
        <b-col cols="12" md="10">
          <div class="video embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              :src="'https://www.youtube.com/embed/NyQT-ATgU9g'"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.commit("SET_NAVBAR", false);
    this.$store.commit("SET_FOOTER", false);
    console.log(this.$route.query.year);
  },
  destroyed() {
    this.$store.commit("SET_NAVBAR", true);
    this.$store.commit("SET_FOOTER", true);
  },
};
</script>

<style scoped lang="scss">
.video {
  position: relative;
  z-index: 100;
}
.fullscreen {
  height: 100vh;
}
.fullscreen_background {
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.fullscreen_background__filter {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
.fullscreen_background__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
